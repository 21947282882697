/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { detect } from "detect-browser";

const getUserMetadata = async () => {
  try {
    const ipAddress = await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => data.ip)
      .catch((error) => console.error("Error fetching IP address:", error));

    const utcTime = new Date().toUTCString();
    const browserInfo = detect();
    const device = browserInfo.os;
    const browser = browserInfo.name;
    const browserVersion = browserInfo.version;

    return {
      utcTime,
      ipAddress,
      device,
      browser,
      browserVersion,
    };
  } catch (error) {
    console.error("Error getting user metadata:", error);
  }
};

export default getUserMetadata;
