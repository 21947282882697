import DOMPurify from "dompurify";
import validator from "validator";

const parseFormData = (values) => {
  const { name, email, phone, isBuyChecked, isSellChecked, metaData } = values;

  const sanitizedName = DOMPurify.sanitize(
    name
      .trim()
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s+/g, " ")
  );

  const sanitizedEmail = validator.isEmail(email.trim())
    ? DOMPurify.sanitize(email.trim())
    : "";

  const sanitizedPhone = DOMPurify.sanitize(
    phone
      .trim()
      .replace(/[^0-9]/g, "")
      .replace(/\s+/g, "")
  );

  const sanitizedOperationBuy = isBuyChecked;
  const sanitizedOperationSell = isSellChecked;
  const sanitizedMetaData = metaData;

  const formData = {
    name: sanitizedName,
    email: sanitizedEmail,
    phone: sanitizedPhone,
    operationBuy: sanitizedOperationBuy,
    operationSell: sanitizedOperationSell,
    metaData: { ...sanitizedMetaData },
    createdAt: new Date().toISOString(),
  };

  return formData;
};

export default parseFormData;
