import React from "react";
import { TypeAnimation } from "react-type-animation";

import "./App.css";

const AppMainForm = ({ onButtonClick }) => {
  const handleButtonClick = () => {
    onButtonClick && onButtonClick();
  };

  return (
    <section className="App-form-section">
      <h1>¡Próximamente!</h1>

      <div className="App-text-animated-wrapper">
        <TypeAnimation
          className="App-text-animated"
          preRenderFirstString={true}
          repeat={Infinity}
          speed={50}
          sequence={[
            "Tu hogar a primera vista",
            5000,
            "Consigue la pareja ideal a tu inmueble en venta",
            5000,
          ]}
        />
      </div>

      <div className="App-form">
        <button
          type="button"
          className="App-button"
          onClick={handleButtonClick}
        >
          ¡Quiero saber más!
          <i className="fa flaticon-next fs-6 mx-2"></i>
        </button>
      </div>
    </section>
  );
};

export default AppMainForm;
