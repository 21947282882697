const icons = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
};

const buttons = {
  OK: {
    confirmButtonText: "Ok",
    confirmButtonColor: "#e62d40",
  },
};

const alerts = {
  LEAD_SAVE_SUCCESSFULLY: {
    title: "¡Gracias por tu interés!",
    text: "El equipo de Casanova te mantendrá actualizado sobre las últimas noticias de su plataforma.",
    icon: icons.SUCCESS,
    ...buttons.OK,
  },
  LEAD_SAVE_ERROR: {
    title: "¡Ups!",
    text: "Ha ocurrido un error al registrar tus datos. Por favor, inténtalo de nuevo más tarde.",
    icon: icons.ERROR,
    ...buttons.OK,
  },
  LEAD_SAVE_DUPLICATED: {
    title: "¡Ups!",
    text: "Ya te has registrado anteriormente. Pronto recibirás noticias de Casanova.",
    icon: icons.INFO,
    ...buttons.OK,
  },
};

export default alerts;
