import { useState } from "react";

import "./App.css";
import logo from "./logo.png";

import AppMainForm from "./AppMainForm";
import AppLeadForm from "./AppLeadForm";
import AppFooterSocials from "./AppFooterSocials";

function App() {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  return (
    <main className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>

      {showForm ? (
        <AppLeadForm onButtonClick={handleHideForm} />
      ) : (
        <AppMainForm onButtonClick={handleShowForm} />
      )}

      {!showForm ? (
        <footer className="App-footer">
          <AppFooterSocials />
          <p>
            Copyright &copy; {new Date().getFullYear()} Casanova | Todos los
            derechos reservados
          </p>
        </footer>
      ) : (
        <div />
      )}
    </main>
  );
}

export default App;
