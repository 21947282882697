import { setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import errors from "./errors";

const saveForm = async (formData) => {
  try {
    const docRef = doc(db, "leads", formData.email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      throw Error(errors.DUPLICATED_LEAD);
    } else {
      await setDoc(docRef, formData);
    }
  } catch (error) {
    if (error.message === errors.DUPLICATED_LEAD) {
      throw error;
    } else {
      throw new Error(errors.FIREBASE_ERROR);
    }
  }
};

export default saveForm;
