import React, { useState } from "react";
import validator from "validator";
import Swal from "sweetalert2";

import "./App.css";

import alerts from "./utils/alerts";
import saveForm from "./utils/saveForm";
import parseFormData from "./utils/parseFormData";
import getUserMetadata from "./utils/userMetadata";
import errors from "./utils/errors";

const EMAIL_PATTERN = "[^@\\s]+@[^@\\s]+\\.[^@\\s]+";

const ErrorAlerts = {
  [errors.DUPLICATED_LEAD]: alerts.LEAD_SAVE_DUPLICATED,
  [errors.FIREBASE_ERROR]: alerts.LEAD_SAVE_ERROR,
};

const AppLeadForm = ({ onButtonClick }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [metaData, setMetaData] = useState({});

  const [isBuyChecked, setIsBuyChecked] = useState(false);
  const [isSellChecked, setIsSellChecked] = useState(false);

  const getMetadata = async () => {
    const data = await getUserMetadata();
    setMetaData(data);
  };

  const handleSaveForm = async () => {
    try {
      const formData = parseFormData({
        name,
        email,
        phone,
        isBuyChecked,
        isSellChecked,
        metaData,
      });

      await saveForm(formData);
      Swal.fire(alerts.LEAD_SAVE_SUCCESSFULLY);
    } catch (error) {
      console.error("Error saving form:", { error, message: error.message });
      Swal.fire(ErrorAlerts[error.message] || alerts.LEAD_SAVE_ERROR);
    }
  };

  const handleSwitchBuyChange = (event) => {
    const { checked } = event.target;
    setIsBuyChecked(checked);
  };

  const handleSwitchSellChange = (event) => {
    const { checked } = event.target;
    setIsSellChecked(checked);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    handleSaveForm();
    onButtonClick && onButtonClick();
  };

  const handleChangeName = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleChangePhone = (event) => {
    const { value } = event.target;
    setPhone(value);
  };

  const isFormInvalid = () => {
    const isValidName = validator.isLength(name, { min: 5, max: 50 });
    const isValidEmail = validator.isEmail(email);

    return !isValidName || !isValidEmail || (!isBuyChecked && !isSellChecked);
  };

  useState(() => {
    getMetadata();
  }, []);

  return (
    <section className="App-form-section form-box bordered shadowed semi-transparent">
      <form onSubmit={handleButtonClick}>
        <div className="App-input-group">
          <label htmlFor="name">¿Cómo te llamas?</label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={handleChangeName}
            minLength={5}
            maxLength={50}
            required
          />
        </div>

        <div className="App-input-group">
          <label htmlFor="email">¿Cuál es tu email?</label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={handleChangeEmail}
            pattern={EMAIL_PATTERN}
            required
          />
        </div>

        <div className="App-input-group">
          <label htmlFor="phone">
            ¿Tu teléfono?
            <span className="text-muted"> (opcional)</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            onChange={handleChangePhone}
          />
        </div>

        <div className="App-switch-group">
          <div className="App-input-group">
            <div className="centered">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isBuyChecked}
                  onChange={handleSwitchBuyChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <span className={isBuyChecked ? "active" : ""}>Quiero comprar</span>
          </div>

          <div className="App-input-group">
            <div className="centered">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isSellChecked}
                  onChange={handleSwitchSellChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <span className={isSellChecked ? "active" : ""}>Quiero vender</span>
          </div>
        </div>

        <div className="App-form">
          <button
            type="submit"
            className="App-button"
            disabled={isFormInvalid()}
          >
            Enviar
            <i className="fa flaticon-next fs-6 mx-2"></i>
          </button>
        </div>
      </form>
    </section>
  );
};

export default AppLeadForm;
